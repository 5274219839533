import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import AuthPage from './AuthPage';
import Dashboard from './pages/Dashboard';
import Organizations from './pages/Organizations';
import OrganizationDisplay from './pages/OrganizationDisplay';
import Attributions from "./Attributions";
import { SpeedInsights } from "@vercel/speed-insights/react";
import Logout from './pages/Logout';
import { Analytics } from "@vercel/analytics/react";
import Subscribe from "./pages/Subscribe";
import './App.css';
import CompanyLogos from './components/CompanyLogos';
import landingPageImage from './components/landingpageimage.png'; // Adjust the path based on your project structure
import { supabase } from "./supabaseClient";
import Notifications from "./components/Notifications";

const Landing = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    const notificationRef = useRef();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const registerEmailDemo = async () => {
        if (!email) {
            setError('Email field cannot be empty.');
            notificationRef.current.showAlertMessage('Email field cannot be empty.', false);
            return;
        }

        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            notificationRef.current.showAlertMessage('Please enter a valid email address.', false);
            return;
        }

        const { data, error } = await supabase
            .from('demoRequests')
            .insert([{ email: email }]);
        if (error) {
            console.error(error);
            setError('An error occurred. Please try again.');
            notificationRef.current.showAlertMessage('An error occurred. Please try again.', false);
        } else {
            setEmail('');
            setError(null);
            notificationRef.current.showAlertMessage('You have been signed up successfully.', true);
        }
    };

    return (
        <div className="bg-dark-main text-white-default min-h-screen font-sans">
            <header className="container mx-auto p-6 flex justify-between items-center">
                <h1 className="text-3xl font-extrabold">LogBug</h1>
                <div className="lg:hidden">
                    <button className="focus:outline-none" onClick={toggleMenu}>
                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
                        </svg>
                    </button>
                </div>
                <nav className="hidden lg:flex space-x-4">
                    <a href="#platform" className="text-gray-400 hover:text-white transition">Platform</a>
                    <a href="#use-cases" className="text-gray-400 hover:text-white transition">Use cases</a>
                    <a href="#why-logbug" className="text-gray-400 hover:text-white transition">Why LogBug</a>
                    <a href="#resources" className="text-gray-400 hover:text-white transition">Resources</a>
                    <a href="#pricing" className="text-gray-400 hover:text-white transition">Pricing</a>
                </nav>
                

            </header>
            {isMenuOpen && (
                <nav className="lg:hidden bg-dark-200 p-4 space-y-4">
                    <a href="#platform" className="block text-gray-400 hover:text-white transition">Platform</a>
                    <a href="#use-cases" className="block text-gray-400 hover:text-white transition">Use cases</a>
                    <a href="#why-logbug" className="block text-gray-400 hover:text-white transition">Why LogBug</a>
                    <a href="#resources" className="block text-gray-400 hover:text-white transition">Resources</a>
                    <a href="#pricing" className="block text-gray-400 hover:text-white transition">Pricing</a>
                  
                </nav>
            )}

            <section id="hero" className="container mx-auto flex flex-col lg:flex-row items-center py-20 lg:py-32">
                <div className="lg:w-1/2 p-6">
                    <h1 className="text-5xl lg:text-6xl font-extrabold mb-4 leading-tight">
                        Everything you need to scale a global team
                    </h1>
                    <p className="text-lg lg:text-xl text-gray-400 mb-6 leading-relaxed">
                        Enhance productivity with LogBug. Streamline your bug tracking and team management processes,
                        ensuring efficient workflows and effective collaboration, no matter where your team is located.
                    </p>
                    <div className="bg-dark-100 p-6 rounded-lg shadow-md">
                        <div className="flex items-center space-x-4">
                            <input
                                type="email"
                                placeholder="Type your awesome email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="flex-1 px-4 py-2 border border-gray-600 bg-dark-main text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                            <button
                                type="button"
                                onClick={registerEmailDemo}
                                className="px-6 py-2 bg-purple text-white rounded-md hover:bg-card-purple ease-in-out transition focus:outline-none focus:ring-2 focus:ring-primary"
                            >
                                Request demo
                            </button>
                        </div>
                        {error && <p className="text-red mt-2">{error}</p>}
                    </div>
                </div>
                <div className="lg:w-1/2 p-6">
                    <div className="bg-dark-100 p-6 rounded-lg shadow-md">
                        <img src={landingPageImage} alt="Landing Illustration" className="rounded-lg"/>
                    </div>
                </div>
            </section>

            <section id="platform" className="py-20 px-6 lg:py-32">
                <div className="container mx-auto px-6 lg:px-0">
                    <h2 className="text-4xl lg:text-5xl font-extrabold mb-8">Platform</h2>
                    <p className="text-lg lg:text-xl text-gray-400 mb-8">LogBug offers a centralized bug tracking system
                        where all bugs can be reported, tracked, and managed efficiently. Our platform integrates
                        seamlessly with your existing tools, ensuring that your team can stay productive without having
                        to switch between multiple applications. Key features include:</p>
                    <ul className="list-disc list-inside text-gray-400 space-y-2">
                        <li>Real-time updates and notifications</li>
                        <li>Comprehensive reporting and analytics</li>
                        <li>Customizable workflows and automation</li>
                        <li>Role-based access control</li>
                        <li>Integration with popular development tools</li>
                    </ul>
                </div>
            </section>

            <section id="use-cases" className="py-20 px-6 lg:py-32 bg-dark-100">
                <div className="container mx-auto px-6 lg:px-0">
                    <h2 className="text-4xl lg:text-5xl font-extrabold mb-8">Use Cases</h2>
                    <p className="text-lg lg:text-xl text-gray-400 mb-8">LogBug is versatile and can be used in various
                        scenarios:</p>
                    <ul className="list-disc list-inside text-gray-400 space-y-2">
                        <li><strong>Software Development:</strong> Track bugs throughout the development lifecycle, from
                            initial discovery to final resolution.
                        </li>
                        <li><strong>Project Management:</strong> Monitor project progress and manage tasks efficiently.
                        </li>
                        <li><strong>Quality Assurance:</strong> Ensure that your product meets the highest quality
                            standards by systematically tracking and resolving issues.
                        </li>
                        <li><strong>Customer Support:</strong> Respond to customer-reported issues quickly and
                            effectively.
                        </li>
                    </ul>
                </div>
            </section>

            <section id="why-logbug" className="py-20 px-6 lg:py-32">
                <div className="container mx-auto px-6 lg:px-0">
                    <h2 className="text-4xl lg:text-5xl font-extrabold mb-8">Why LogBug</h2>
                    <p className="text-lg lg:text-xl text-gray-400 mb-8">LogBug is designed to be the ultimate tool for
                        bug tracking and team management. Here's why you should choose LogBug:</p>
                    <ul className="list-disc list-inside text-gray-400 space-y-2">
                        <li><strong>User-Friendly Interface:</strong> Our intuitive interface makes it easy for your
                            team to get started with minimal training.
                        </li>
                        <li><strong>Scalability:</strong> Whether you're a small team or a large enterprise, LogBug
                            scales with your needs.
                        </li>
                        <li><strong>Customizable Workflows:</strong> Tailor LogBug to fit your team's unique processes
                            and requirements.
                        </li>
                        <li><strong>Robust Security:</strong> Protect your data with industry-leading security measures.
                        </li>
                        <li><strong>Dedicated Support:</strong> Our support team is here to help you with any issues or
                            questions.
                        </li>
                    </ul>
                </div>
            </section>

            <section id="resources" className="py-20 lg:py-32 px-6 bg-dark-100">
                <div className="container mx-auto px-6 lg:px-0">
                    <h2 className="text-4xl lg:text-5xl font-extrabold mb-8">Resources</h2>
                    <p className="text-lg lg:text-xl text-gray-400 mb-8">Access a wealth of resources to help you get
                        the most out of LogBug:</p>
                    <ul className="list-disc list-inside text-gray-400 space-y-2">
                        <li><strong>Documentation:</strong> Comprehensive guides and tutorials to help you navigate
                            LogBug.
                        </li>
                        <li><strong>Community:</strong> Join our community forum to share tips, ask questions, and
                            collaborate with other LogBug users.
                        </li>
                        <li><strong>Webinars:</strong> Participate in live and recorded webinars to learn from the
                            experts.
                        </li>
                        <li><strong>Support:</strong> Get in touch with our support team for personalized assistance.
                        </li>
                    </ul>
                </div>
            </section>

            <section id="pricing" className="py-20 px-6 lg:py-32">
                <div className="container mx-auto px-6 lg:px-0">
                    <h2 className="text-4xl lg:text-5xl font-extrabold mb-8">Pricing</h2>
                    <p className="text-lg lg:text-xl text-gray-400 mb-8">LogBug offers flexible pricing plans to suit
                        different needs and budgets:</p>
                    <ul className="list-disc list-inside text-gray-400 space-y-2">
                        <li><strong>Basic Plan:</strong> Essential features for small teams.</li>
                        <li><strong>Pro Plan:</strong> Advanced features for growing teams.</li>
                        <li><strong>Enterprise Plan:</strong> Custom solutions for large organizations.</li>
                    </ul>
                    <p className="text-lg lg:text-xl text-gray-400 mt-8">Contact us for a custom quote or to discuss
                        your specific requirements.</p>
                </div>
            </section>

            <footer className="py-8 bg-dark-200 text-center">
                <p className="text-gray-500">&copy; 2024 LogBug. All rights reserved.</p>
            </footer>
            <Notifications ref={notificationRef} />
        </div>
    );
};

const App = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Landing/>}/>
                    <Route path="/auth" element={<AuthPage/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/organizations" element={<Organizations/>}/>
                    <Route path="organizationDisplay/:orgID" element={<OrganizationDisplay/>}/>
                    <Route path="/speed-insights" element={<SpeedInsights/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/attributions" element={<Attributions/>}/>
                    <Route path="/subscribe" element={<Subscribe/>}/>
                    <Route path="*">"404 Not Found!"</Route>
                </Routes>
            </Router>
            <Analytics/>
        </>
    );
};

export default App;
